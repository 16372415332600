<template>
  <CardToolbox>
    <sdPageHeader title="All coupons">
      <template #buttons> </template>
    </sdPageHeader>
  </CardToolbox>

  <Main>
    <a-row :gutter="15">
      <a-col :xs="24"> </a-col>
    </a-row>
    <a-row :gutter="15">
      <a-col :xs="24">
        <TableStyleWrapper>
          <TableWrapper class="table-responsive">
            <a-table
              :dataSource="tableData"
              :columns="tableColumns"
              :loading="isLoading"
              :pagination="{
                current: pagination.currentPage,
                defaultPageSize: pagination.perPage,
                total: pagination.total,
                showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
              }"
              @change="handleTableChange"
            />
          </TableWrapper>
        </TableStyleWrapper>
      </a-col>
    </a-row>
    <MarketingModal v-if="visible" />
  </Main>
</template>
<script>
import { TableStyleWrapper } from './style';
import { computed, onMounted, watch } from 'vue';
import { useStore } from 'vuex';
import { Main, CardToolbox, TableWrapper } from '@/view/styled';
import MarketingModal from './overview/MarketingModal.vue';

const tableColumns = [
  {
    title: 'Coupon Id',
    dataIndex: 'coupon_id',
    key: 'coupon_id',
  },
  {
    title: 'Code',
    dataIndex: 'code',
    key: 'code',
  },
  {
    title: 'Is Primary',
    dataIndex: 'is_primary',
    key: 'is_primary',
  },
  {
    title: 'Rule Id',
    dataIndex: 'rule_id',
    key: 'rule_id',
  },
  {
    title: 'Times Used',
    dataIndex: 'times_used',
    key: 'times_used',
  },
  {
    title: 'Type',
    dataIndex: 'type',
    key: 'type',
  },
  {
    title: 'Actions',
    dataIndex: 'action',
    key: 'action',
    width: '90px',
  },
];

const MarketingTable = {
  name: 'MarketingTable',
  components: { Main, CardToolbox, TableWrapper, TableStyleWrapper, MarketingModal },
  setup() {
    const { state, dispatch, commit } = useStore();

    const pagination = computed(() => state.marketing.pagination);
    const handleTableChange = async (p) => {
      commit('updatePagination', { key: 'currentPage', value: p.current });
      await dispatch('fetchMarketing');
    };

    const isLoading = computed(() => state.marketing.loading);
    const visible = computed(() => state.marketing.visible);

    // const technicianData = computed(() => state.marketing.technicianData);
    const coupons = computed(() => state.marketing.coupons);
    const tableData = computed(() =>
      coupons.value.map((coupon) => {
        const { coupon_id, code, is_primary, rule_id, times_used, type } = coupon;

        return {
          coupon_id,
          code,
          is_primary: is_primary ? 'Yes' : 'No',
          rule_id,
          times_used,
          type,
          action: (
            <div class="table-actions">
              <>
                <sdButton
                  class="btn-icon"
                  type="default"
                  shape="circle"
                  onClick={async () => {
                    await dispatch('fetchSalesRule', rule_id);
                    commit('setShowMarketingModal', true);
                  }}
                >
                  <sdFeatherIcons type="edit" size="16" />
                </sdButton>
              </>
            </div>
          ),
        };
      }),
    );

    onMounted(() => {
      dispatch('fetchCoupons');
    });

    const currentStoreView = computed(() => state.auth.activeStoreView);
    watch(currentStoreView, async () => {
      commit('updatePagination', { key: 'currentPage', value: 1 });
      await dispatch('fetchCoupons');
    });

    return {
      tableColumns,
      tableData,
      isLoading,
      pagination,
      handleTableChange,
      visible,
    };
  },
};

export default MarketingTable;
</script>
