<template>
  <BasicFormWrapper>
    <ProductFilterWrapper>
      <a-form name="multi-form" layout="vertical" class="products-search-filter" @keyup.enter.prevent="applyFilters">
        <a-row :gutter="30">
          <a-col :lg="12" :sm="24" :xs="24">
            <a-form-item label="Customer Name">
              <a-input tabindex="3" v-model:value="formState.customer" @keyup.enter.prevent="applyFilters" />
            </a-form-item>
          </a-col>
          <a-col :lg="12" :sm="24" :xs="24">
            <a-form-item label="Technician">
              <a-select v-model:value="formState.technician" mode="multiple">
                <a-select-option :value="technician.id" :key="technician.id" v-for="technician in technicianData">{{
                  technician.name
                }}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :lg="12" :sm="24" :xs="24">
            <a-form-item label="Order ID">
              <a-input tabindex="3" v-model:value="formState.orderId" @keyup.enter.prevent="applyFilters" />
            </a-form-item>
          </a-col>
          <a-col :md="12" :xs="24">
            <a-form-item label="By Booked Date">
              <div class="sub-form-item mb-20">
                <span>from</span>
                <a-date-picker :value="formState.from" @change="(date, dateString) => (formState.from = dateString)" />
              </div>
              <div class="sub-form-item">
                <span>to</span>
                <a-date-picker :value="formState.to" @change="(date, dateString) => (formState.to = dateString)" />
              </div>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row justify="end" :gutter="8">
          <a-col flex>
            <sdButton type="primary" :raised="true" @click="applyFilters" :disable="isSaving">
              {{ isSaving ? 'applying...' : 'Search' }}
            </sdButton>
            <sdButton
              type="primary"
              :style="{ marginLeft: '32px !important' }"
              :raised="true"
              @click="clearFilters"
              :disable="true"
            >
              {{ 'Clear Filters' }}
            </sdButton>
          </a-col>
        </a-row>
      </a-form>
    </ProductFilterWrapper>
  </BasicFormWrapper>
</template>

<script>
import { BasicFormWrapper } from '../../styled';
import { ProductFilterWrapper } from './style';
import { computed, ref, onMounted } from 'vue';
import { useStore } from 'vuex';

const initialFilters = {
  customer: '',
  technician: [],
  from: '',
  to: '',
  orderId: '',
};

export default {
  name: 'ProductFilters',
  components: { BasicFormWrapper, ProductFilterWrapper },
  setup() {
    const { state, dispatch, commit } = useStore();
    const filters = computed(() => state.appointments.filters);
    const technicianData = computed(() => state.appointments.technicianData);
    const formState = ref(filters);
    const isSaving = computed(() => state.appointments.saving);

    const applyFilters = async () => {
      console.log(formState.value, 'filters');
      commit('setAppointmentFilters', formState.value);
      commit('setHasOrder', false);
      await dispatch('fetchAppointments');
    };

    const clearFilters = async () => {
      Object.assign(formState.value, initialFilters);
      commit('setAppointmentFilters', initialFilters);
      await dispatch('fetchAppointments');
      // commit('setShow', false);
    };

    onMounted(async () => {
      await dispatch('fetchTechnicians');
    });

    return {
      formState,
      isSaving,
      applyFilters,
      clearFilters,
      technicianData,
    };
  },
};
</script>
