<template>
  <a-modal
    v-model:visible="visible"
    title=""
    @ok="handleOk"
    @cancel="handleCancel"
    okText="OK"
    cancelText=""
    class="appointment-edit-modal"
  >
    <sdPageHeader title="Sales Rule" />
    <Main>
      <sdCards>
        <template #button> </template>
        <FileCardWrap>
          <BasicFormWrapper>
            <div v-if="isSaving || isLoading" class="spin">
              <a-spin />
            </div>
            <a-form v-else name="multi-form" layout="horizontal">
              <a-row :gutter="30">
                <a-col :md="12" :xs="24" class="mb-25" :key="key" v-for="[key, value] of Object.entries(salesRule)">
                  {{ key + ':' + JSON.stringify(value) }}
                </a-col>
              </a-row>
            </a-form>
          </BasicFormWrapper>
        </FileCardWrap>
      </sdCards>
    </Main>
  </a-modal>
</template>

<script>
import { FileCardWrap } from './style';
import { computed, onMounted } from 'vue';
import { BasicFormWrapper, Main } from '@/view/styled';
import { useStore } from 'vuex';

export default {
  name: 'AppointmentModal',
  components: { FileCardWrap, BasicFormWrapper, Main },
  props: {},
  setup() {
    const { state, commit } = useStore();
    const isSaving = computed(() => state.marketing.saving);
    const isLoading = computed(() => state.marketing.loading);
    const visible = computed(() => state.marketing.visible);
    const salesRule = computed(() => state.marketing.salesRule);

    const handleCancel = () => {
      commit('setShowMarketingModal', false);
    };

    const onSave = () => {
      //   push({ name: 'omni-marketing-list' });
      handleCancel();
    };

    const handleOk = () => {
      onSave();
    };

    onMounted(async () => {});

    return {
      onSave,
      isSaving,
      isLoading,
      handleCancel,
      handleOk,
      visible,
      salesRule,
    };
  },
};
</script>
