<template>
  <sdPageHeader title="Enquiry Details" />
  <Main>
    <sdCards>
      <template #button>
        <sdButton class="btn-signin" type="primary" @click="onSave" :disabled="isSaving">Save</sdButton>
      </template>
      <FileCardWrap>
        <BasicFormWrapper>
          <div v-if="isSaving || isLoading" class="spin">
            <a-spin />
          </div>
          <a-form v-else name="multi-form" layout="horizontal">
            <a-row :gutter="30">
              <a-col :md="12" :xs="24" class="mb-25">
                <a-form-item label="First Name">
                  <a-input v-model:value="enquiry.first_name" placeholder="Please type your first name." />
                </a-form-item>
              </a-col>
              <a-col :md="12" :xs="24" class="mb-25">
                <a-form-item label="Last Name">
                  <a-input
                    v-model:value="enquiry.last_name"
                    placeholder="Please type your last name. Your name should have at least one character."
                  />
                </a-form-item>
              </a-col>
            </a-row>
            <a-row :gutter="30">
              <a-col :md="12" :xs="24" class="mb-25">
                <a-form-item label="Email">
                  <a-input v-model:value="enquiry.email" placeholder="Please enter a valid email address." />
                </a-form-item>
              </a-col>
              <a-col :md="12" :xs="24" class="mb-25">
                <a-form-item label="Phone Number">
                  <a-input v-model:value="enquiry.phone" placeholder="Please type your phone number." />
                </a-form-item>
              </a-col>
            </a-row>
            <a-row :gutter="30">
              <a-col :md="12" :xs="24" class="mb-25">
                <a-form-item label="Registration / VIN">
                  <a-input v-model:value="enquiry.vin" placeholder="Please enter a valid VIN code." />
                </a-form-item>
              </a-col>
              <a-col :md="12" :xs="24" class="mb-25">
                <a-form-item label="Item Required">
                  <a-input v-model:value="enquiry.item_required" placeholder="Please enter a valid VIN code." />
                </a-form-item>
              </a-col>
            </a-row>
            <a-row :gutter="30">
              <a-col :md="12" :xs="24" class="mb-25">
                <a-form-item label="Status">
                  <a-select v-model:value="enquiry.status">
                    <a-select-option value="open">Open</a-select-option>
                    <a-select-option value="pending">Pending</a-select-option>
                    <a-select-option value="completed">Completed</a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
            </a-row>
            <a-row :gutter="30">
              <a-col :xs="24" class="mb-25">
                <a-form-item label="Message">
                  <a-textarea
                    maxlength="400"
                    minlength="10"
                    wrap="soft"
                    rows="8"
                    v-model:value="enquiry.message"
                    placeholder="type a message"
                  />
                </a-form-item>
              </a-col>
            </a-row>
          </a-form>
        </BasicFormWrapper>
      </FileCardWrap>
    </sdCards>
  </Main>
</template>

<script>
import { FileCardWrap } from './style';
import { computed, onMounted } from 'vue';
import { BasicFormWrapper, Main } from '@/view/styled';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';

export default {
  name: 'EnquiryDetails',
  components: { FileCardWrap, BasicFormWrapper, Main },
  setup() {
    const { dispatch, state, commit } = useStore();
    const { params } = useRoute();
    const enquiry = computed(() => state.enquiries.enquiry);
    const isSaving = computed(() => state.enquiries.saving);
    const isLoading = computed(() => state.enquiries.loading);

    const onSave = () => {
      dispatch('updateEnquiry');
    };

    onMounted(async () => {
      if (params.id === 'create') {
        commit('initEnquiry');
      } else {
        await dispatch('fetchEnquiry', params.id);
      }
    });

    return {
      enquiry,
      onSave,
      isSaving,
      isLoading,
    };
  },
};
</script>
