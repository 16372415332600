<template>
  <CardToolbox>
    <sdPageHeader title="All enquiries">
      <template #subTitle>
        <sdAutoComplete
          :value="searchKey"
          width="100%"
          placeholder="Search by Email"
          patterns
          @change="onSearchChange"
        />
      </template>
      <template #buttons>
        <sdButton class="btn-add_new" size="default" type="primary" key="1">
          <router-link to="/omni/enquiries/create">+ Add New Enquiry</router-link>
        </sdButton>
      </template>
    </sdPageHeader>
  </CardToolbox>

  <Main>
    <a-row :gutter="15">
      <a-col :xs="24">
        <TableStyleWrapper>
          <TableWrapper class="table-responsive">
            <a-table
              :dataSource="tableData"
              :columns="tableColumns"
              :loading="isLoading"
              }
              :pagination="{
                current: pagination.currentPage,
                defaultPageSize: pagination.perPage,
                total: pagination.total,
                showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
              }"
              @change="handleTableChange"
            />
          </TableWrapper>
        </TableStyleWrapper>
      </a-col>
    </a-row>
  </Main>
</template>
<script>
import { TableStyleWrapper } from './style';
import { computed, onMounted, watch } from 'vue';
import { useStore } from 'vuex';
import { Main, CardToolbox, TableWrapper } from '@/view/styled';
import { dateFormat } from '@/utility/filter';

const tableColumns = [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Email',
    dataIndex: 'email',
    key: 'email',
  },
  {
    title: 'Phone',
    dataIndex: 'phone',
    key: 'phone',
  },
  {
    title: 'Item Required',
    dataIndex: 'item_required',
    key: 'item_required',
  },
  {
    title: 'Registration / VIN',
    dataIndex: 'vin',
    key: 'vin',
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
  },
  {
    title: 'Last Update Date',
    dataIndex: 'updated_at',
    key: 'updated_at',
  },
  {
    title: 'Actions',
    dataIndex: 'action',
    key: 'action',
    width: '90px',
  },
];

const EnquiriesTable = {
  name: 'EnquiriesTable',
  components: { Main, CardToolbox, TableWrapper, TableStyleWrapper },
  setup() {
    const { state, dispatch, commit } = useStore();

    const searchKey = computed(() => state.enquiries.searchKey);
    const onSearchChange = (email) => {
      commit('setEnquiriesSearchEmail', email);
      dispatch('fetchEnquiries');
    };

    const pagination = computed(() => state.enquiries.pagination);
    const handleTableChange = async (p) => {
      commit('updatePagination', { key: 'currentPage', value: p.current });
      await dispatch('fetchEnquiries');
    };

    const isLoading = computed(() => state.enquiries.loading);

    const enquiries = computed(() => state.enquiries.enquiries);
    const tableData = computed(() =>
      enquiries.value.map((enquiry) => {
        const { id, first_name, last_name, email, phone, vin, item_required, status, updated_at } = enquiry;

        const to = {
          name: 'omni-enquiries-details',
          params: { id },
        };

        const labelColors = {
          open: 'magenta',
          pending: 'gold',
          completed: 'cyan',
        };

        return {
          key: id,
          name: `${first_name} ${last_name}`,
          email,
          phone,
          item_required,
          vin,
          status: (
            <div class="taglist-wrap">
              <a-tag color={labelColors[status]}>{status}</a-tag>
            </div>
          ),
          updated_at: dateFormat(updated_at),
          action: (
            <div class="table-actions">
              <>
                <router-link to={to}>
                  <sdButton class="btn-icon" type="default" shape="circle">
                    <sdFeatherIcons type="edit" size="16" />
                  </sdButton>
                </router-link>
                <a-popconfirm title="Sure to delete?" onConfirm={() => dispatch('deleteEnquiry', id)}>
                  <sdButton class="btn-icon" type="default" shape="circle">
                    <sdFeatherIcons type="trash" size="16" />
                  </sdButton>
                </a-popconfirm>
              </>
            </div>
          ),
        };
      }),
    );

    onMounted(() => {
      dispatch('fetchEnquiries');
    });

    const currentStoreView = computed(() => state.auth.activeStoreView);
    watch(currentStoreView, async () => {
      commit('updatePagination', { key: 'currentPage', value: 1 });
      await dispatch('fetchEnquiries');
    });

    return {
      searchKey,
      enquiries,
      tableColumns,
      onSearchChange,
      tableData,
      isLoading,
      pagination,
      handleTableChange,
    };
  },
};

export default EnquiriesTable;
</script>
