<template>
  <div class="dashboard">
    <a-row>
      <a-col :md="12" :xs="24">
        <TitleContainer>
          <h2>Reporting</h2>
          <small>Last Data Refresh: 10 minutes ago<span></span></small>
        </TitleContainer>
      </a-col>
      <a-col :md="12" :xs="24">
        <SelectContainer>
          <div class="selector" v-if="user.is_admin">
            <span>Clients:</span>
            <a-select v-model:value="form.client_id">
              <a-select-option :value="0">All</a-select-option>
              <a-select-option :value="client.id" v-for="client in clients" :key="client.id">{{
                client.name
              }}</a-select-option>
            </a-select>
          </div>
          <div class="selector multiple">
            <span>StoreId:</span>
            <a-select v-model:value="form.store_id" mode="multiple">
              <a-select-option :key="storeview.id" :value="storeview.id" v-for="storeview in user.store_views">{{
                storeview.code
              }}</a-select-option>
            </a-select>
          </div>
          <DatePickerWrapper>
            <a-range-picker
              :ranges="{
                Today: [moment(), moment()],
                'This Month': [moment(), moment().endOf('month')],
              }"
              :default-value="[moment(), moment()]"
              @change="onChange"
            />
          </DatePickerWrapper>
        </SelectContainer>
      </a-col>
    </a-row>
    <a-row>
      <a-col :xs="8" class="card"
        ><sdCards>
          <TotalContainer>
            <small>New Sales</small>
            <p>{{ reportingData }}</p>
            <i>icon</i>
          </TotalContainer>
        </sdCards></a-col
      >
      <a-col :xs="8" class="card"
        ><sdCards
          ><TotalContainer
            ><small>Number of Orders</small>
            <p>{{ orderNumbers }}</p>
            <i>icon</i></TotalContainer
          ></sdCards
        ></a-col
      >
      <a-col :xs="8" class="card"
        ><sdCards>
          <TotalContainer
            ><small>Number of Enquiries</small>
            <p>{{ enquiryNumbers }}</p>
            <i>icon</i></TotalContainer
          ></sdCards
        ></a-col
      >
    </a-row>
    <a-row>
      <a-col :xs="12" class="card">
        <sdCards>Chart</sdCards>
      </a-col>
      <a-col :xs="12" class="card"><sdCards>table</sdCards> </a-col>
    </a-row>
  </div>
</template>

<script>
import { reactive, computed, watch, onMounted } from 'vue';
import { TitleContainer, SelectContainer, TotalContainer, DatePickerWrapper } from './style';
import moment from 'moment';
import { useStore } from 'vuex';

export default {
  name: 'ReportDashboard',
  components: { TitleContainer, SelectContainer, TotalContainer, DatePickerWrapper },
  setup() {
    const form = reactive({
      store_id: [],
      start_date: new Date().toISOString().split('T')[0],
      end_date: new Date().toISOString().split('T')[0],
      client_id: '',
    });

    const { state, dispatch } = useStore();
    const clients = computed(() => state.clients.clients);
    const user = computed(() => state.auth.user);
    const currentStoreView = computed(() => state.auth.activeStoreView);
    const reportingData = computed(() => state.reporting.reportingData);
    const orderNumbers = computed(() => state.reporting.orderNumbers);
    const enquiryNumbers = computed(() => state.reporting.enquiryNumbers);
    if (currentStoreView.value.id) form.store_id.push(currentStoreView.value.id);
    form.client_id = user.value.client_id;
    if (user.value.is_admin) form.client_id = 0;
    const onChange = (date, dateString) => {
      console.log(date, 'date');
      form.start_date = dateString[0];
      form.end_date = dateString[1];
    };

    watch(
      () => form,
      (value) => {
        dispatch('getReportingData', value);
      },
      { deep: true },
    );

    onMounted(() => {
      dispatch('getReportingData', form);
    });

    return {
      form,
      user,
      onChange,
      moment,
      reportingData,
      orderNumbers,
      clients,
      enquiryNumbers,
    };
  },
};
</script>

<style lang="scss" scoped>
.dashboard {
  padding: 15px;
  height: calc(100vh - 65px);
}

.ant-card {
  margin-bottom: 0;
}

.card {
  padding: 7.5px;
}

.multiple {
  padding: 4px 10px;
}
</style>
