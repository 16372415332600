<template>
  <CardToolbox>
    <sdPageHeader title="All appointments">
      <!-- <template #subTitle>
        <sdAutoComplete
          :value="searchKey"
          width="100%"
          placeholder="Search by Technician"
          patterns
          @change="onSearchChange"
        />
      </template> -->
      <template #buttons>
        <sdButton class="btn-add_new" size="default" type="primary" key="1" @click="newAppointment">
          + Add New Appointment
        </sdButton>
      </template>
    </sdPageHeader>
    <AppointmentFilters />
  </CardToolbox>

  <Main>
    <a-row :gutter="15">
      <a-col :xs="24"> </a-col>
    </a-row>
    <a-row :gutter="15">
      <a-col :xs="24">
        <TableStyleWrapper>
          <TableWrapper class="table-responsive">
            <a-table
              :dataSource="tableData"
              :columns="tableColumns"
              :loading="isLoading"
              :pagination="{
                current: pagination.currentPage,
                defaultPageSize: pagination.perPage,
                total: pagination.total,
                showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
              }"
              @change="handleTableChange"
            />
          </TableWrapper>
        </TableStyleWrapper>
      </a-col>
    </a-row>
    <AppointmentModal v-if="visible" />
  </Main>
</template>
<script>
import { TableStyleWrapper } from './style';
import { computed, onMounted, watch } from 'vue';
import { useStore } from 'vuex';
import { Main, CardToolbox, TableWrapper } from '@/view/styled';
import AppointmentFilters from './AppointmentFilters';
// import { dateFormat } from '@/utility/filter';
import AppointmentModal from './overview/AppointmentModal.vue';

const tableColumns = [
  {
    title: 'Date',
    dataIndex: 'date',
    key: 'date',
  },
  {
    title: 'Start Time',
    dataIndex: 'start_time',
    key: 'start_time',
  },
  {
    title: 'End Time',
    dataIndex: 'end_time',
    key: 'end_time',
  },
  {
    title: 'Customer',
    dataIndex: 'customer',
    key: 'cuntomer',
  },
  {
    title: 'Order ID',
    dataIndex: 'order_id',
    key: 'order_id',
  },
  // {
  //   title: 'Booked Online',
  //   dataIndex: 'booked_online',
  //   key: 'booked_online',
  // },
  // {
  //   title: 'Slot ID',
  //   dataIndex: 'slot_id',
  //   key: 'slot_id',
  // },
  // {
  //   title: 'Note',
  //   dataIndex: 'note',
  //   key: 'note',
  // },
  // {
  //   title: 'Internal Booking',
  //   dataIndex: 'internal_booking',
  //   key: 'internal_booking',
  // },
  {
    title: 'Technician ID',
    dataIndex: 'technician_id',
    key: 'technician_id',
  },
  {
    title: 'Actions',
    dataIndex: 'action',
    key: 'action',
    width: '90px',
  },
];

const AppointmentsTable = {
  name: 'AppointmentsTable',
  components: { Main, CardToolbox, TableWrapper, TableStyleWrapper, AppointmentFilters, AppointmentModal },
  setup() {
    const { state, dispatch, commit } = useStore();

    const searchKey = computed(() => state.appointments.searchKey);
    const onSearchChange = (email) => {
      commit('setAppointmentsSearchTechnician', email);
      commit('setHasOrder', false);
      dispatch('fetchAppointments');
    };

    const pagination = computed(() => state.appointments.pagination);
    const handleTableChange = async (p) => {
      commit('updatePagination', { key: 'currentPage', value: p.current });
      commit('setHasOrder', false);
      await dispatch('fetchAppointments');
    };

    const newAppointment = async () => {
      await commit('initAppointmentData');
      commit('setIsEdit', false);
      commit('setShowModal', true);
    };

    const isLoading = computed(() => state.appointments.loading);
    const visible = computed(() => state.omni.showModal);

    // const technicianData = computed(() => state.appointments.technicianData);
    const appointments = computed(() => state.appointments.appointmentsData);
    const tableData = computed(() =>
      appointments.value.map((appointment) => {
        const {
          id,
          start_time,
          end_time,
          order_id,
          // booked_online,
          // slot_id,
          // note,
          // internal_booking,
          technician,
          customer,
        } = appointment;

        // const to = {
        //   name: 'omni-appointments-edit',
        //   params: { id },
        // };

        let date = start_time?.slice(0, 11);
        // const labelColors = {
        //   open: 'magenta',
        //   pending: 'gold',
        //   completed: 'cyan',
        // };
        // let technician = technicianData.value?.filter(technician => technician.id === technician_id);
        // technician = technician?.length > 0 ? technician[0]?.name : technician?.name;

        return {
          date,
          start_time: start_time && start_time.slice(11, 16),
          end_time: end_time && end_time?.slice(11, 16),
          customer,
          order_id,
          // booked_online,
          // slot_id,
          // note,
          // internal_booking: internal_booking ? 'Yes' : 'No',
          technician_id: technician?.name,
          action: (
            <div class="table-actions">
              <>
                <sdButton
                  class="btn-icon"
                  type="default"
                  shape="circle"
                  onClick={async () => {
                    await dispatch('fetchAppointmentData', id);
                    commit('setIsEdit', true);
                    commit('setShowModal', true);
                  }}
                >
                  <sdFeatherIcons type="edit" size="16" />
                </sdButton>

                <a-popconfirm title="Sure to delete?" onConfirm={() => dispatch('deleteAppointment', id)}>
                  <sdButton class="btn-icon" type="default" shape="circle">
                    <sdFeatherIcons type="trash" size="16" />
                  </sdButton>
                </a-popconfirm>
              </>
            </div>
          ),
        };
      }),
    );

    onMounted(() => {
      dispatch('fetchTechnicians');
      commit('setHasOrder', false);
      dispatch('fetchAppointments');
    });

    const currentStoreView = computed(() => state.auth.activeStoreView);
    watch(currentStoreView, async () => {
      commit('updatePagination', { key: 'currentPage', value: 1 });
      await dispatch('fetchEnquiries');
    });

    return {
      searchKey,
      appointments,
      tableColumns,
      onSearchChange,
      tableData,
      isLoading,
      pagination,
      handleTableChange,
      visible,
      newAppointment,
    };
  },
};

export default AppointmentsTable;
</script>
