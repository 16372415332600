<template>
  <sdPageHeader title="Appointment Details" />
  <Main>
    <sdCards>
      <template #button>
        <sdButton class="btn-signin" type="primary" @click="onSave" :disabled="isSaving">Save</sdButton>
      </template>
      <FileCardWrap>
        <BasicFormWrapper>
          <div v-if="isSaving || isLoading" class="spin">
            <a-spin />
          </div>
          <a-form v-else name="multi-form" layout="horizontal">
            <a-row :gutter="30">
              <a-col :md="12" :xs="24" class="mb-25">
                <a-form-item label="Customer">
                  <a-input v-model:value="appointment.customer" placeholder="Please type your customer name." />
                </a-form-item>
              </a-col>
              <a-col :md="12" :xs="24" class="mb-25">
                <a-form-item label="Order ID">
                  <a-input v-model:value="appointment.order_id" placeholder="Please type your order id." />
                </a-form-item>
              </a-col>
            </a-row>
            <a-row :gutter="30">
              <a-col :md="24" :xs="24" class="mb-25">
                <a-form-item label="Note">
                  <a-input v-model:value="appointment.note" placeholder="Please type your note." />
                </a-form-item>
              </a-col>
            </a-row>
            <a-row :gutter="30">
              <a-col :md="12" :xs="24" class="mb-25">
                <a-form-item label="Technician">
                  <a-select v-model:value="appointment.technician_id" mode="multiple">
                    <a-select-option :value="technician.id" :key="technician.id" v-for="technician in technicianData">{{
                      technician.name
                    }}</a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
            </a-row>
            <div class="booking__component">
              <a-row :gutter="30">
                <a-col :md="24" :xs="24" class="mb-25 arrows">
                  <span class="arrows__button" @click="prevWeek">&lt;</span>
                  <span class="arrows__text">{{ schedulePeriod }}</span>
                  <span class="arrows__button" @click="nextWeek">&gt;</span>
                </a-col>
              </a-row>
              <a-row :gutter="30">
                <a-col :md="24" :xs="24" class="mb-25 schedule-day-selector">
                  <div
                    v-for="date in weeks[0]?.days"
                    :key="date.date"
                    class="schedule-day-selector__button"
                    @click="getAppointment(date.payload)"
                    :class="isSelected(date.payload) ? 'selected' : ''"
                  >
                    <div class="schedule-day-selector__button--day">{{ date.day }}</div>
                    <div class="schedule-day-selector__button--date">{{ date.date }}</div>
                  </div>
                </a-col>
              </a-row>
              <a-row :gutter="30">
                <a-col :md="24" :xs="24" class="mb-25">
                  <OmRaio v-for="appointment in appointments" :key="appointment.id" :appointment="appointment" />
                </a-col>
              </a-row>
            </div>
          </a-form>
        </BasicFormWrapper>
      </FileCardWrap>
    </sdCards>
  </Main>
</template>

<script>
import { FileCardWrap } from './style';
import { computed, onMounted } from 'vue';
import { BasicFormWrapper, Main } from '@/view/styled';
import OmRaio from './OmRadio.vue';
import { useStore } from 'vuex';
import dayjs from 'dayjs';
import { useRoute, useRouter } from 'vue-router';

export default {
  name: 'AppointmentDetails',
  components: { FileCardWrap, BasicFormWrapper, Main, OmRaio },
  props: {
    period: {
      type: Number,
      default: () => 5,
    },
    intervals: {
      type: Array,
      required: true,
    },
    appointmentDuration: {
      type: Number,
      default: () => 30,
    },
    minWeeks: {
      type: Number,
      default: () => 1,
    },
  },
  setup(props) {
    const { dispatch, state, commit } = useStore();
    const { push } = useRouter();
    const weeks = computed(() => state.appointments.weeks);
    const days = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'];
    const appointments = computed(() => state.appointments.appointments);
    const isSaving = computed(() => state.appointments.saving);
    const isLoading = computed(() => state.appointments.loading);
    const today = computed(() => state.appointments.currentDate);

    const { params } = useRoute();
    const appointment = computed(() => state.appointments.appointmentData);
    const slotData = computed(() => state.appointments.slotData);
    const technicianData = computed(() => state.appointments.technicianData);
    console.log(appointment.value, 'appoint');
    const dates = [
      { day: 'WED', date: 25 },
      { day: 'TUE', date: 26 },
      { day: 'FRI', date: 27 },
      { day: 'SAT', date: 28 },
      { day: 'SUN', date: 29 },
    ];

    const onSave = () => {
      dispatch('setAppointment', appointment.value);
      push({ name: 'omni-appointments-list' });
    };

    const nextWeek = () => {
      console.log(weeks.value.length, 'weeks');
      let newWeek = weeks.value.length
        ? dayjs(weeks.value[weeks.value.length - 1]?.week[0]?.schedule[props.period - 1]?.end)
        : dayjs();
      console.log(newWeek, 'newWeek');

      let weeks1 = [];
      let weekDays = [];
      for (let w = 0; w <= props.period - 1; w++) {
        let schedule = [];

        weekDays.push({
          date: newWeek.date(),
          day: days[newWeek.day()],
          payload: new Date(newWeek.$d.toISOString().split('T')[0]).toISOString(),
        });
        weeks1.push(schedule);
        newWeek = newWeek.add(1, 'day');
      }

      let schedule = {
        days: [],
        week: [],
      };
      for (let s = 0; s < weeks1[0].length; s++) {
        let week = [];
        for (let w = 0; w <= props.period - 1; w++) {
          week.push(weeks1[w][s]);
        }
        schedule.week.push({
          label: `${weeks1[0][s].start.split(' ')[1].substring(-3, 5)} - ${weeks1[0][s].end
            .split(' ')[1]
            .substring(-3, 5)}`,
          schedule: week,
        });
      }
      schedule.days = weekDays;
      console.log(schedule.days, 'days');
      commit('setWeeks', [schedule]);
      dispatch('fetchAppointment', schedule.days[0].payload);
    };

    const prevWeek = () => {
      let newWeek = weeks.value.length
        ? dayjs(weeks.value[weeks.value.length - 1]?.week[0]?.schedule[0]?.end).add(-props.period, 'day')
        : dayjs();
      // const startDayOfWeek = newWeek.day();

      let weeks1 = [];
      let weekDays = [];
      for (let w = 0; w <= props.period - 1; w++) {
        let schedule = [];

        weekDays.push({
          date: newWeek.date(),
          day: days[newWeek.day()],
          payload: new Date(newWeek.$d.toISOString().split('T')[0]).toISOString(),
        });
        weeks1.push(schedule);
        newWeek = newWeek.add(1, 'day');
      }

      let schedule = {
        days: [],
        week: [],
      };
      for (let s = 0; s < weeks1[0].length; s++) {
        let week = [];
        for (let w = 0; w <= props.period - 1; w++) {
          week.push(weeks[w][s]);
        }
        schedule.week.push({
          label: `${weeks1[0][s].start.split(' ')[1].substring(-3, 5)} - ${weeks1[0][s].end
            .split(' ')[1]
            .substring(-3, 5)}`,
          schedule: week,
        });
      }
      schedule.days = weekDays;
      console.log(schedule.days, 'days');
      commit('setWeeks', [schedule]);
      dispatch('fetchAppointment', schedule.days[0].payload);
    };

    const schedulePeriod = computed(() => {
      const from = dayjs(state.appointments.weeks[0]?.days[0]?.payload).format('dddd DD, MMM');

      const to = dayjs(state.appointments.weeks[0]?.days[props.period - 1]?.payload).format('dddd DD');

      return `${from} - ${to}`;
    });

    const getAppointment = (date) => {
      commit('setCurrentDay', date);
      dispatch('fetchAppointment', date);
    };

    const initWeeks = () => {
      let min = props.minWeeks;
      do {
        nextWeek();
        min--;
      } while (min > 0);
    };

    const isSelected = (date) => {
      return today.value === date;
    };

    onMounted(async () => {
      initWeeks();
      let date = new Date(new Date().toISOString().split('T')[0]).toISOString();
      commit('setCurrentDay', date);
      dispatch('fetchAppointment', date);

      await dispatch('fetchSlotIDS', date);
      await dispatch('fetchTechnicians');
      console.log(params.id, 'id');
      if (params.id === 'new') {
        commit('initAppointmentData');
        commit('initSlotIds');
        commit('setIsEdit', false);
      } else {
        await dispatch('fetchAppointmentData', params.id);
        commit('setIsEdit', true);
      }
    });

    return {
      appointments,
      onSave,
      isSaving,
      isLoading,
      dates,
      nextWeek,
      prevWeek,
      weeks,
      schedulePeriod,
      initWeeks,
      getAppointment,
      isSelected,
      appointment,
      slotData,
      technicianData,
    };
  },
};
</script>
