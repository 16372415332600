import Styled from 'vue3-styled-components';

const TitleContainer = Styled.div`
    width: 100%;
    font-size: 16px;
    font-weight: 600;
    line-height: 1.5;
    padding: 15px
    
    h2 {
        font-size: 30px;
    }

    small {
        color: grey;
    }
`;

const SelectContainer = Styled.div`
    width: 100%;
    padding: 15px;
    display: flex;
    font-size: 16px;
    font-weight: 600;
    line-height: 1.5;
    justify-content: end;
    align-items: center;

    .selector {
        border: 1px soild grey;
        background-color: white;
        padding: 10px;
        margin-right: 15px;
    }

    div {
        border-radius: 4px;

        span{ 
            margin-right: 10px
        }
    }
`;

const TotalContainer = Styled.div`
    width: 100%;
    padding: 15px;
    font-size: 16px;
    font-weight: 600;
    line-height: 1.5;
    position: relative;

    p {
        font-size: 24px;
    }

    i {
        position: absolute;
        right: 10px;
        top: 50%;
        transform: translateY(-50%);
    }
`;

const DatePickerWrapper = Styled.div`
    padding: 12px;
    background-color: white;
`;

export { TitleContainer, SelectContainer, TotalContainer, DatePickerWrapper };
